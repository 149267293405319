<template>
  <div id="app">
    <Header 
      :name="user.first_name" 
      :treeName="treeConfig.name" />
    <Intro 
      :name="user.first_name" 
      :text="treeConfig.intro_text"
      :treeId="treeConfig.id" />
    <Stats :treeStats="treeConfig.stats" />
    <Maps
      :userNumber="user.number" />
    <ForestInfo />
    <Ecotree />
    <Links 
      :userId="user.id"
      :number="user.number" />
    <section class="container is-max-widescreen">
      <img class="forest-img" src="@/assets/img/forest.gif" alt="">
    </section>
    <VFooter />
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Intro from './components/Intro.vue';
import Stats from './components/Stats.vue';
import Maps from './components/Maps.vue';
import ForestInfo from './components/ForestInfo.vue';
import {TREE_CONFIG} from './treeConfig';
import {USERS} from './users';
import Ecotree from './components/Ecotree.vue';
import VFooter from './components/VFooter.vue';
import Links from './components/Links.vue';

export default {
  name: 'App',

  components: {
    Header,
    Intro,
    Stats,
    Maps,
    ForestInfo,
    Ecotree,
    VFooter,
    Links
  },

  computed: {
    treeConfig() {
      return TREE_CONFIG[this.user.species];
    },

    user() {
      const currentURL = new URL(window.location.href);
      const paramID = currentURL.searchParams.get('id');

      return USERS.find(user => {
        return user.id == paramID;
      });
    },
  },
}
</script>

<style lang="scss">
@import "./assets/scss/_variables.scss";
@import "./assets/scss/_typography.scss";

#app {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $c-black;
}

.forest-img {
  max-width: 960px;
  width: 100%;
  margin: 2.5rem auto 1.5rem;
  display: block;

  @include mq-tablet() {
    margin: 2.5rem auto;
  }
}
</style>
