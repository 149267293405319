<template>
  <section class="container is-max-widescreen">
    <div class="columns">
      <div class="column is-6 img-col">
        <img src="@/assets/img/logo_ecotree.svg" alt="Ecotree">
      </div>
      <div class="column is-6">
        <p class="paragraph">
        <b>About Ecotree</b><br><br>
        To make our forest come true we partnered with French sustainable forestry company Ecotree. By expanding and protecting European forests Ecotree reduces our CO<sub>2</sub> footprint, stimulates biodiversity and produces sustainable building material.<br><br>
        On <a href="https://ecotree.green/" target="_blank">ecotree.green</a> you can read all about the Ecotree concept of sustainable forestry. In a nutshell Ecotree makes it possible for companies and individuals to participate in the forests they maintain by buying trees which will increase in value on the long run. <br><br>
        Your Meijer &amp; Walters tree is our gift to you, so you don’t have to pay for it. If you would like to claim your revenue in the future, please register at the Ecotree website and request a certificate.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ecotree',
}
</script>

<style lang="scss" scoped>
  @import "./../assets/scss/_variables.scss";

  .container {
    padding: 12rem 20px 3rem;

    @include mq-tablet() {
      padding: 20rem 0 5.25rem;
    }
  }

  .img-col {
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
      max-width: 175px;

      @include mq-tablet() {
        max-width: 290px;
      }
    }
  }

  .paragraph {
    @include mq-mobile-only {
      margin-top: 15px;
    }
  }
</style>