<template>
  <section class="wrapper">
    <YoutubeModal 
      :playing="isPlaying"
      @onClose="handleModalClose"
      videoId="qL365hiKWlo"
    />
    <div class="container is-max-widescreen">
      <div class="columns is-variable is-8">
        <div class="column is-6">
          <h2 class="heading2">Pleyben Forest</h2>
          <p class="paragraph">In the centre of Finistère, between the Mountains Arrée and the Black Mountains, Pleyben shelters one of the most beautiful parish enclosures in the region, a legacy of the Breton Golden Age (16th century). To the north of the town stands our forest.<br><br>
          You will discover a place full of life that’s populated by many species: for example, chestnut, cherrywood, or red oak trees. Wild boars, roe deer, foxes and even ermine gleefully hide themselves in the nature there. From the forest, you can see Mont Saint-Michel of Brasparts, the highest point in Brittany (385 m). It dominates the Yeun Elez, an area of marshes and moors where, according to Breton legend, the Gate of the Underworld is located. Beware if you meet Lankou there: Death personified!</p>
        </div>
        <div class="column is-6">
          <div 
            @click="handleModalOpen"
            class="thumbnail">
            <img class="video-img" src="@/assets/img/thumbnail.jpg" alt="">
            <img class="play-btn" src="@/assets/img/play.svg" alt="">
          </div>
        </div>
      </div>
      <img class="bg-img" src="@/assets/img/forest-small.svg" alt="">
      <div class="stats-box">
        <div class="columns is-variable is-2 is-multiline">
          <div class="column is-6">
            <h5>Temperature</h5>
            <h6><b>{{ weatherData.main ? weatherData.main.temp : '' }}°C</b></h6>
          </div>
          <div class="column is-6">
            <h5>Humidity</h5>
            <h6><b>{{ weatherData.main ? weatherData.main.humidity : ''  }}%</b></h6>
          </div>
          <div class="column is-6">
            <h5>Wind</h5>
            <h6>{{ weatherData.main ? weatherData.wind.speed : ''  }} km/h</h6>
          </div>
          <div class="column is-6">
            <h5>Atmospheric pressure</h5>
            <h6>{{ weatherData.main ? weatherData.main.pressure : ''  }} hPa</h6>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import YoutubeModal from './YoutubeModal.vue';
import axios from 'axios';

const lat = 48.2451152;
const lon = -4.0440902;
const APIkey = '95e150c8dc3a246f9cfaf0048e2359f0';

export default {
  name: 'forest-info',

  components: {
    YoutubeModal
  },

  data() {
    return {
      weatherData: {},
      isPlaying: false
    }
  },

  mounted() {
    axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${APIkey}&units=metric`)
    .then(response => {
      this.weatherData = response.data;
    })
  },

  methods: {
    handleModalOpen() {
      this.isPlaying = true;
    },

    handleModalClose() {
      this.isPlaying = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./../assets/scss/_variables.scss";

  .wrapper {
    background: $c-green;
    position: relative;
  }

  .container {
    padding: 1.875rem 20px 12.5REM;
    
    @include mq-tablet() {
      padding-top: 5.25rem;
      padding-bottom: 12.5rem;
    }
  }

  .bg-img {
    position: absolute;
    bottom: 35px;
    right: -35px;
    z-index: 1;
    width: 186px;

    @include mq-tablet() {
      width: 30%;
      bottom: 20px;
      right: 20px;
    }
  }

  .stats-box {
    background: $c-yellow;
    padding: 1.5rem 1.25rem;
    position: absolute;
    width: 50%;
    top: 82%;

    @include mq-tablet() {
      width: 60%;
      top: 85%;
      padding: 3rem 2.5rem;
    }

    h5 {
      font-size: 0.875rem;

      @include mq-tablet() {
        font-size: 1.575rem
      }
    }

    h6 {
      font-size: 1.125rem;
      font-weight: 600;

      @include mq-tablet() {
        font-size: 2rem;
      }
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      left: -10px;
      top: 10px;
      background: $c-green;
      z-index: -1;
      position: absolute;

      @include mq-tablet() {
        left: -20px;
        top: 20px;
      }
    }
  }

  .thumbnail {
    position: relative;
    cursor: pointer;

    @include mq-mobile-only() {
      margin-top: 20px;
    }

    &:hover {
      .play-btn {
        transform: scale(1.05);
      }
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      right: -10px;
      top: -10px;
      background: $c-yellow;
      z-index: 1;
      position: absolute;

      @include mq-tablet() {
        right: -20px;
        top: -20px;
      }
    }

    .video-img {
      position: relative;
      z-index: 2;
      width: 100%;
    }

    .play-btn {
      width: 70px;
      height: 70px;
      position: absolute;
      z-index: 3;
      top: calc(50% - 35px);
      left: calc(50% - 35px);
      transform: scale(1);
      transition: .2s ease-out;

      @include mq-tablet() {
        width: 130px;
        height: 130px;
        top: calc(50% - 65px);
        left: calc(50% - 65px);
      }
    }
  }
</style>