<template>
  <section class="wrapper">
    <div class="container is-max-widescreen">
      <div class="columns is-variable is-8">
        <div class="column is-6 yellow-bg">
          <h2 class="heading2">Request your certificate</h2>
          <p class="paragraph">As the official owner of your tree, you have the right to profit from the tree’s revenue in about 70 years time. If you intend to claim your money in the future, please register at Ecotree and request a certificate of ownership.<br><br>
          <a href="https://ecotree.green/en/receive-gift" target="_blank"><b>Claim your tree here</b></a></p>
        </div>
        <div class="column is-6 has-text-centered green-bg">
          <h2 class="heading2">Share your tree!</h2>
          <ul class="sns">
            <li class="sns__icon">
              <a :href="`https://www.facebook.com/sharer/sharer.php?u=https://forest.meijerwalters.com/?id=${userId}`" target="_blank"><img src="@/assets/img/icons/facebook-xl.svg" alt="Facebook"></a>
            </li>
            <li class="sns__icon">
              <a :href="`https://twitter.com/intent/tweet?url=https://forest.meijerwalters.com/?id=${userId}&text=${text}`" target="_blank"><img src="@/assets/img/icons/twitter-xl.svg" alt="Twitter"></a>
            </li>
            <li class="sns__icon">
              <a :href="`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(`https://forest.meijerwalters.com/?id=${userId}&summary=${text}`)}`" target="_blank"><img src="@/assets/img/icons/linkedin-xl.svg" alt="LinkedIn"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'links',

  props: {
    number: {
      type: Number,
      required: true
    },

    userId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      text: "Look%2C%20I%20got%20a%20tree%20in%20the%20Meijer%20%26%20Walters%20Forest%21%20%0AIf%20you%20also%20want%20a%20tree%2C%20go%20to%20ecotree.green"
    }
  }
}
</script>


<style lang="scss" scoped>
  @import "./../assets/scss/_variables.scss";

  .wrapper {
    background: linear-gradient(90deg, rgba(255,241,0,1) 0%, rgba(255,241,0,1) 50%, rgba(123,240,164,1) 50%, rgba(123,240,164,1) 100%);
    width: 100%;
  }

  .yellow-bg {
    background: $c-yellow;

    @include mq-tablet() {
      padding-right: 9rem !important;
    }
  }
  
  .green-bg {
    background: $c-green;
  }

  .column {
    @include mq-mobile-only() {
      padding: 1.875rem 3.25rem 2.5rem !important;
    }

    @include mq-tablet() {
      padding-top: 6.25rem;
      padding-bottom: 6.25rem;
    }
  }

  .sns {
    width: 100%;
    margin-top: 20px;

    @include mq-tablet() {
      margin-top: 30px;
    }

    &__icon {
      transition: .2s ease-out;
      width: 45px;
      display: inline-block;
      opacity: 1;

      @include mq-tablet() {
        width: 70px;
      }

      &:hover {
        opacity: .65;
      }

      + .sns__icon {
        margin-left: 20px;

        @include mq-tablet() {
          margin-left: 30px;
        }
      }
    }
  }
</style>