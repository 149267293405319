export const TREE_CONFIG = {
  cherrywood: {
    id: 1,
    name: "Cherrywood Tree",
    intro_text: "Meet your tree in the Meijer & Walters Forest! It’s a wonderful and lush cherrywood tree, which has been around for a while. Thanks to you, it is now protected and treated with care by the special people of Ecotree.<br><br>Originating from the shores of the Caspian Sea, this species spread to Europe in prehistoric times. The cherrywood (Prunus avium), also called wild cherry, is a vigorous deciduous tree of the Rosaceae family. It is the only tree of its family to spread naturally in the forests. It produces small red globular fruits, the wild cherries, which turn black as they ripen; they are edible and are used in the making of kirsch. The cherrywood tree plays an important role for biodiversity in European forest ecosystems. In addition, it has great biological potential: it enhances the development or maintenance of other species.",
    stats: {
      location: "48.2451152,-4.0440902",
      co2_absorbed: "447.06 kg",
      age: "33 years",
      time_to_cut: "11 years",
      growth: "rapid",
      height: "15-30 meters",
      foliage: "alternate, oval, pendulous, dentate with two glands",
      trunk: "straight, with a slender appearance",
      bark: "rough and shiny, it comes off in horizontal strips"
    }
  },
  oak: {
    id: 2,
    name: "Red Oak Tree",
    intro_text: "Meet your tree in the Meijer &amp; Walters Forest! It’s a wonderful and lush red oak tree, which has been around for a while. Thanks to you, it is now protected and treated with care by the special people of Ecotree.<br><br>The northern red oak (Quercus rubra) is a deciduous oak tree in the red oak group. Native of North America, it was introduced to Europe in the 18th century and has naturalized throughout most of western and central Europe. The red oak is primarily found on the edges of woodland reserves in Europe, where light availability, tannin concentration, and animal dispersal guarantee the species’ longevity and survival. The acorns develop on the tree for two growing seasons and are released from the tree in early October. Its kernel is white and very bitter and are eaten by deer, squirrels and birds.",
    stats: {
      location: "48.2451152,-4.0440902",
      co2_absorbed: "447.06 kg",
      age: "35 years",
      time_to_cut: "16 years",
      growth: "rapid",
      height: "30-40 meters",
      foliage: "alternate, seven to nine-lobed, oblong-ovate to oblong",
      trunk: "straight and tall (50-100 diameter)",
      bark: "dark reddish gray-brown, with broad, thin, rounded ridges, scaly"
    }
  },
  chestnut: {
    id: 3,
    name: "Chestnut Tree",
    intro_text: "Meet your tree in the Meijer &amp; Walters Forest! It’s a wonderful and lush chestnut tree, which has been around for a while. Thanks to you, it is now protected and treated with care by the special people of Ecotree.<br><br>Chestnut comes from the Latin word Castanea originating from Asia Minor and the Caucasus region. It is a deciduous tree of the Fagaceae family. It produces fruit: chestnuts. When the trees are in abundance in a defined area, it forms a chestnut grove. In autumn, this foliage turns into a pretty yellow colour, which quickly turns brown. As with oak and beech, two trees of the same family, the leaves of chestnut remain on the tree for part of the winter. This vegetation doesn’t flower until after about twenty years.",
    stats: {
      location: "48.2451152,-4.0440902",
      co2_absorbed: "447.06 kg",
      age: "10 years",
      time_to_cut: "41 years",
      growth: "rapid",
      height: "30 meters",
      foliage: "alternate, stalked, tough leaves with large, sharp teeth",
      trunk: "straight, short and with a wide spread crown",
      bark: "cracked lengthwise",
      color: "dark brown"
    }
  }
}