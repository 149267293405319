<template>
  <section class="wrapper">
    <div class="maps">
      <GmapMap
        :center="{
          lat: 48.269726, 
          lng: -3.978377
        }"
        :zoom="16"
        map-type-id="satellite"
        style="width: 100%; height: 100%"
         :options="{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false
        }"
      >
        <GmapMarker
          :position="{
            lat: parseFloat(activeMarker.lat), 
            lng: parseFloat(activeMarker.lng)
          }"
          :icon="{ url: require('./../assets/img/icons/marker-green.svg')}"
        />
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="{
            lat: parseFloat(m.lat), 
            lng: parseFloat(m.lng)
          }"
          :icon="{ url: require('./../assets/img/icons/marker.svg')}"
          :zIndex="-2000000"
        />
      </GmapMap>
    </div>
    <div class="container is-max-widescreen">
      <div class="columns is-variable is-8">
        <div class="column is-5">
          <div class="overlay">
            <h2 class="heading2">This is our forest</h2>
            <p class="paragraph">You recognize your tree by the green pin on the map. As you see, it is surrounded by the other trees of our Meijer &amp; Walters Forest. Check out your neighbours!</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {USERS} from './../users';
import {TREE_DATA} from './../treeData';

export default {
  name: 'maps',

  props:{
    userNumber: {
      type: [Number, String],
      required: true
    }
  },

  data() {
    return {
      markers: [],
      activeMarker: {}
    }
  },

  computed: {
    users() {
      return USERS;
    },
  },

  mounted() {
    this.setMarkers();
  },

  methods: {
    setMarkers() {
      const treeNumbers = USERS.map(user => user.number);
      // Set active marker
      this.activeMarker = TREE_DATA.find(tree => tree.id == this.userNumber);
      // Remove active marker
      treeNumbers.splice(this.userNumber, 1);
      // Set other markers
      this.markers = TREE_DATA.filter(tree => treeNumbers.includes(parseFloat(tree.id)));
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./../assets/scss/_variables.scss";

  .container {
    pointer-events: none;
    position: relative;
    z-index: 2;
  }

  .wrapper {
    @include mq-tablet() {
      height: 580px;
      position: relative;
    }
  }

  .maps {
    background: #efefef;
    height: 260px;

    @include mq-tablet() {
      position: absolute;
      background: #efefef;
      left: 0;
      top: 0;
      width: 100%;
      height: 580px;
      z-index: 1;
    }
  }

  .overlay {
    background: $c-white;
    padding: 1.875rem 20px;
    
    @include mq-tablet() {
      padding: 2.5rem;
      position: relative;
      margin-top: 5.25rem;

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        left: -20px;
        top: 20px;
        background: $c-yellow;
        position: absolute;
        z-index: -1;
      }
    }
  }
</style>