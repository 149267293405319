<template>
  <section class="container is-max-widescreen">
    <div class="columns is-variable is-8">
      <div class="column is-6">
        <div class="stats-box">
          <div class="columns is-mobile is-variable is-2 is-multiline">
            <div class="column is-12">
              <h5>Location</h5>
              <h6>{{ treeStats.location }}</h6>
            </div>
            <div class="column is-12">
              <h5>CO<sub>2</sub> absorbed (per year) </h5>
              <h6>{{ treeStats.co2_absorbed }}</h6>
            </div>
            <div class="column is-6">
              <h5>Age</h5>
              <h6>{{ treeStats.age }}</h6>
            </div>
            <div class="column is-6">
              <h5>Time before cutting</h5>
              <h6>{{ treeStats.time_to_cut }}</h6>
            </div>
          </div>
        </div>
        <div class="secondary-stats">
          <p><b>Growth</b> {{ treeStats.growth }}</p>
          <p><b>Height</b> {{ treeStats.height }}</p>
          <p><b>Foliage</b> {{ treeStats.foliage }}</p>
          <p><b>Trunk</b> {{ treeStats.trunk }}</p>
          <p><b>Bark</b> {{ treeStats.bark }}</p>
          <p v-if="treeStats.color"><b>Color</b> {{ treeStats.color }}</p>
        </div>
      </div>
      <div class="column is-6 img-col">
        <img src="@/assets/img/map.svg" alt="">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'stats',

  props: {
    treeStats: {
      type: Object,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "./../assets/scss/_variables.scss";

  .container {
    padding-bottom: 1.875rem;
    padding-left: 20px;
    padding-right: 20px;
    
    @include mq-tablet() {
      padding-bottom: 5.25rem;
      padding-left: 0;
      padding-right: 0;   
    }
  }

  .stats-box {
    background: $c-yellow;
    padding: 1.5rem 1.25rem;
    position: relative;

    @include mq-tablet() {
      padding: 3rem 2.5rem;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      left: -10px;
      top: 10px;
      background: $c-green;
      z-index: -1;
      position: absolute;

      @include mq-tablet() {
        left: -20px;
        top: 20px;
      }
    }
  }

  h5 {
    font-size: 0.875rem;

    @include mq-tablet() {
      font-size: 1.575rem
    }
  }

  h6 {
    font-weight: 600;
    font-size: 1.125rem;

    @include mq-tablet() {
      font-size: 2rem;
    }
  }

  .secondary-stats {
    padding-top: 5.25rem;

    p {
      display: block;

      @include mq-tablet() {
        white-space: nowrap;
      }

      + p {
        margin-top: 0.75rem;

        @include mq-tablet() {
          margin-top: 0.5rem;
        }
      }
    }
  }

  .img-col {
    display: flex;
    align-items: flex-end;
      
    @include mq-mobile-only() {
      padding: 0;
      position: absolute;
      z-index: -1;
      width: 90%;
      right: -30%;
      bottom: 17.5%;
    }

    img {
      max-width: initial;
      width: 100%;

      @include mq-tablet() {
        width: 860px;
      }
    }
  }
</style>