<template>
  <div 
    @click="handleClose"
    class="youtube-modal"
    :class="{'youtube-modal--open': playing}">
    <div class="embed">
      <youtube 
        ref="player"
        :player-vars="{
          autoplay: 0,
          modestbranding: 1,
        }"
        :video-id="videoId" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'youtube-modal',
  
  props: {
    videoId: {
      type: String,
      required: true
    },

    playing: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    playing() {
      if (!this.playing) {
        this.$refs.player.player.stopVideo();
      } else {
        this.$refs.player.player.playVideo();
      }
    }
  },

  methods: {
    handleClose() {
      this.$emit('onClose');
    }
  }
}
</script>

<style lang="scss" scoped>
  .youtube-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background: rgba(0,0,0,0.85);
    width: 100vw;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: .3s ease-out;

    &--open {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      cursor: pointer;
    }

    .embed {
      position: absolute;
      max-width: 80%;
      max-height: 80%;
      width: 80%;
      height: 80%;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      
      ::v-deep iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
</style>