var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"wrapper"},[_c('div',{staticClass:"maps"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"100%"},attrs:{"center":{
        lat: 48.269726, 
        lng: -3.978377
      },"zoom":16,"map-type-id":"satellite","options":{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
      }}},[_c('GmapMarker',{attrs:{"position":{
          lat: parseFloat(_vm.activeMarker.lat), 
          lng: parseFloat(_vm.activeMarker.lng)
        },"icon":{ url: require('./../assets/img/icons/marker-green.svg')}}}),_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":{
          lat: parseFloat(m.lat), 
          lng: parseFloat(m.lng)
        },"icon":{ url: require('./../assets/img/icons/marker.svg')},"zIndex":-2000000}})})],2)],1),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-max-widescreen"},[_c('div',{staticClass:"columns is-variable is-8"},[_c('div',{staticClass:"column is-5"},[_c('div',{staticClass:"overlay"},[_c('h2',{staticClass:"heading2"},[_vm._v("This is our forest")]),_c('p',{staticClass:"paragraph"},[_vm._v("You recognize your tree by the green pin on the map. As you see, it is surrounded by the other trees of our Meijer & Walters Forest. Check out your neighbours!")])])])])])}]

export { render, staticRenderFns }