<template>
  <section class="container is-max-widescreen">
    <div class="columns">
      <div class="column is-6">
        <p class="paragraph">
          Hello {{ name }},
        </p>
        <p class="paragraph" v-html="text"></p>
      </div>
      <div class="column is-6 tree-col">
        <div class="tree-img">
          <img v-if="treeId === 1" src="@/assets/img/tree_1.jpg">
          <img v-if="treeId === 2" src="@/assets/img/tree_2.jpg">
          <img v-if="treeId === 3" src="@/assets/img/tree_3.jpg">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'intro',

  props: {
    name: {
      type: String,
      required: true
    },

    text: {
      type: String,
      required: true
    },

    treeId: {
      type: Number,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "./../assets/scss/_variables.scss";

  .container {
    padding-top: 1.875rem;
    padding-bottom: .5rem;
    padding-left: 20px;
    padding-right: 20px;
    
    @include mq-tablet() {
      padding-top: 5.25rem;
      padding-bottom: 5.25rem;  
      padding-left: 0;
      padding-right: 0;   
    }
  }

  .tree-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tree-img {
    position: relative;

    @include mq-mobile-only {
      display: none;
    }

    img {
      border-radius: 100%;
      max-width: 340px;
      z-index: 2;
      position: relative;
    }
  }
</style>