import Vue from 'vue'
import App from './App.vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYouTubeEmbed from 'vue-youtube-embed';
import './assets/scss/styles.scss';

Vue.config.productionTip = false

Vue.use(VueYouTubeEmbed);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCMhhX1shB8agCEJA1dJUY2-jbGTJwJSGc',
    libraries: 'places',
  },
})

new Vue({
  render: h => h(App),
}).$mount('#app')
