<template>
  <section class="header">
    <div class="container is-max-widescreen">
      <div class="columns">
        <div class="column is-12">
          <p class="site-title">
            <b>Meijer &amp; Walters</b><br>
            Let’s get a forest together
          </p>
          <h1 class="heading1">{{`${name}${name.endsWith('s') ? "’" : "’s"}`}}<br>
          <span>{{ treeName }}</span></h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Header',

  props: {
    treeName: {
      type: String,
      required: true
    },

    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../assets/scss/_variables.scss";

.header {
  width: 100%;
  height: 175px;
  background-image: url('../assets/img/header.jpg');
  background-size: cover;
  background-position: center;

  @include mq-tablet() {
    height: 500px;
  }
  
  .container {
    height: 100%;

    @include mq-mobile-only() {
      padding: 0 20px;
    }
  }

  .columns {
    height: 100%;
  }

  .column {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding-top: 55px;

    @include mq-tablet() {
      padding-top: 80px;
    }
  }

  .heading1 {
    color: $c-white;
    font-weight: 600;
    text-transform: uppercase;

    span {
      color: $c-yellow;
    }
  }

  .site-title {
    color: #fff;
    position: absolute;
    font-size: 14px;
    line-height: 1.25;
    top: 25px;
    left: 15px;

    @include mq-tablet() {
      font-size: 20px;    
      top: 50px;
      left: 15px;
    }
  }
}
</style>
