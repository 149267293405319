<template>
  <footer class="footer">
    <section class="container is-max-widescreen">
      <div class="columns">
        <div class="column is-4">
          <p class="paragraph">
            <b><a href="https://www.meijerwalters.com/" target="_blank">Meijer &amp; Walters</a></b>
          </p>
          <p class="paragraph">
            <a href="https://goo.gl/maps/LnP2ZgAQ3aYcqNpb6" target="_blank">Tweede Helmersstraat 90 hs<br>
            1054 CN Amsterdam</a>
          </p>
          <p class="paragraph">
            <a href="mailto:info@meijerwalters.com">info@meijerwalters.com</a><br>
            <a href="tel:+31202237441">+31 (0)20 223 7441</a>
          </p>
        </div>
        <div class="column is-4">
          <p class="paragraph">
            <b><a href="https://www.meijerwalters.com/" target="_blank"><u>Visit our website</u></a></b>
          </p>
          <p class="paragraph"><b>Follow us on social</b></p>
          <ul class="sns">
            <li class="sns__icon">
              <a href="https://www.facebook.com/meijerwalters/" target="_blank"><img src="@/assets/img/icons/facebook.svg" alt="Facebook"></a>
            </li>
            <li class="sns__icon">
              <a href="https://twitter.com/meijerwalters" target="_blank"><img src="@/assets/img/icons/twitter.svg" alt="Twitter"></a>
            </li>
            <li class="sns__icon">
              <a href="https://www.instagram.com/meijerwalters/" target="_blank"><img src="@/assets/img/icons/instagram.svg" alt="Instagram"></a>
            </li>
            <li class="sns__icon">
              <a href="https://www.linkedin.com/company/meijer-&amp;-walters" target="_blank"><img src="@/assets/img/icons/linkedin.svg" alt="LinkedIn"></a>
            </li>
          </ul>
        </div>
        <div class="column is-4 menu">
          <p class="paragraph">
            <a href="https://www.meijerwalters.com/privacy-policy/" target="_blank">Privacy Policy</a><br>
            <a href="https://www.meijerwalters.com/cookie-policy/" target="_blank">Cookie Policy</a>
          </p>
          <img class="logo" src="@/assets/img/logo.svg" alt="Meijer &amp; Walters">
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: 'v-footer'
}
</script>

<style lang="scss" scoped>
@import "./../assets/scss/_variables.scss";

.footer {
  background: $c-black;
  padding: 1.875rem 20px;

  @include mq-tablet() {
    padding: 3.75rem 0 10rem;
  }

  .paragraph {
    color: $c-white;
    display: block;

    + .paragraph {
      margin-top: 1.5rem;
    }

    a {
      color: $c-white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .column + .column {
    @include mq-mobile-only {
      margin-top: 20px;
    }
  }

  .menu {
    @include mq-mobile-only {
      margin-top: 40px !important;
    }

    .paragraph a {
      color: #888888;
    }

    .logo {
      margin-top: 20px;
      
      @include mq-tablet() {
        margin-top: 40px;
      }
    }
  }

  .sns {
    width: 100%;
    margin-top: 10px;

    &__icon {
      transition: .2s ease-out;
      width: 30px;
      height: 30px;
      display: inline-block;
      opacity: 1;

      @include mq-tablet() {
        width: 40px;
        height: 40px;
      }

      &:hover {
        opacity: .65;
      }

      + .sns__icon {
        margin-left: 20px;
      }
    }
  }
}
</style>